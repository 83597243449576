import * as React from "react"
import { Layout } from "../layouts/layout"

export default function NotFoundPage() {
  return (
    <Layout>
      <div className="">
        <h1>404</h1>
      </div>
    </Layout>
  )
}
